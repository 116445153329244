import { FetcherWithComponents, useNavigation } from '@remix-run/react';

export function useIsSubmitting(fetcher?: FetcherWithComponents<any>) {
  const navigation = useNavigation();

  if (fetcher) {
    return fetcher.state === 'submitting';
  }

  return navigation.state === 'submitting';
}
